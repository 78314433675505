<template>
  <v-main :style="[mobileCheck ? { paddingTop: '35px' } : {}]">
    <router-view />
  </v-main>
</template>

<script>
import user from "@/store/auth"
import { CompaniesApiUrls } from '../../../../services/accommodationRequests/companies.api';
export default {
  name: 'DashboardCoreView',
  data: () => {
    return{
      companyId: localStorage.getItem("company_id"),
      companyName: localStorage.getItem("company_name"),
    }
  },
  computed: {
    mobileCheck() {
      return window.innerWidth < window.innerHeight && window.innerWidth < 480 || window.innerWidth > window.innerHeight && window.innerHeight < 480
    },
    getCompany(){
      return user.getCompany
    }
  },
  // async mounted() {
  //   if (!localStorage.getItem("company_name")) {
  //     let companyId = localStorage.getItem("company_id")
  //     let getCompanyInfo = await CompaniesApiUrls.getSlug(companyId)
  //     user.commit("setCompanyName", getCompanyInfo.name);
  //     user.commit("setSlug", getCompanyInfo.slug);
  //   }
  // },
  watch: {
    companyId: {
      async handler(newVal) {
        if (!this.companyName) {
          const getCompanyInfo = await CompaniesApiUrls.getSlug(newVal);
          user.commit("setCompanyName", getCompanyInfo.name);
          user.commit("setSlug", getCompanyInfo.slug);
          this.companyName = getCompanyInfo.name; 
        }
      },
      immediate: true, 
    },
  },
}
</script>
